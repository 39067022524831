<template>
  <div>
    <window-header></window-header>
    <nav-form :onlyIcons="true">
      <li slot="actions" @click="exportarCSVValores">Exportar valores</li>
    </nav-form>
    <section>
      <article style="height: 162px; width: 500px">
        <field
          name="nombre"
          widget="char"
          searchable
          label="Nombre"
          help="Nombre interno para este atributo"
          placeholder="Nombre"
          width="200px"
          style="top: 4px; left: 9px"
          required
        />
        <field
          name="nombre_web"
          widget="char"
          searchable
          label="Descripcion web"
          required
          help="Nombre Publico para este atributo, mostrado a los clientes"
          placeholder="Nombre Publico"
          width="200px"
          style="top: 44px; left: 9px"
        />
        <field
          name="tipo"
          label="Tipo de atributo"
          searchable
          :options="{
            'Lista desplegable': 'select',
            'Botones de radio': 'radio',
            Color: 'color',
          }"
          width="150px"
          widget="select"
          style="top: 84px; left: 4px"
        />
        <field
          ref="valores"
          name="valores"
          widget="handsontable"
          style="top: 10px; left: 218px; width: 272px"
          :height="131"
          :minRows="5"
          :allowHideColumns="false"
          :htSettings="htSettingsValores"
          :columns="[
            { name: 'nombre', header: 'Valor' },
            { name: 'color', header: 'Color' },
          ]"
        />
      </article>
    </section>
    <v-collapse-wrapper ref="main-collapse" :active="true">
      <div class="header" v-collapse-toggle>
        <div class="item-title">Listado</div>
        <div class="item-after">{{ count }}</div>
      </div>
      <div class="my-content" v-collapse-content>
        <hot-table ref="hotTableComponent" :settings="htSettings"></hot-table>
      </div>
    </v-collapse-wrapper>
  </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import rFormMixin from "./../components/rFormMixin.vue";
import rTableMixin from "./../components/rTableMixin.vue";
export default {
  mixins: [WindowMixin, rFormMixin, rTableMixin],
  data: function () {
    return {
      title: "Atributos",
      dbAdapter: "atributo",
      primary: "id",
      htSettingsValores: {
        manualColumnResize: false,
        manualColumnMove: false,
      },
    };
  },
  watch: {
    "formData.tipo": function (newVal, oldVal) {
      this.setValoresCols(newVal);
    },
  },
  methods: {
    exportarCSVValores() {
      let self = this;
      window.open(
        window.DB.server +
          "valor_atributo" +
          "?" +
          window.$.param({
            action: "exportCSV",
            token: self.app.session.token,
          }),
        "_blank"
      );
    },
    setValoresCols(tipo) {
      let self = this;
      let valoresField = self.$refs.valores.field;
      if (tipo == "color") {
        valoresField.columnsData = [
          {
            name: "nombre",
            header: "Valor",
            width: 150,
          },
          {
            name: "color",
            header: "Color",
            width: 50,
            type: "color",
          },
        ];
      } else {
        valoresField.columnsData = [
          {
            name: "nombre",
            header: "Valor",
            width: 210,
          },
        ];
      }
    },
  },
  mounted() {
    var self = this;
    self.setValoresCols(null);
  },
};
</script>
